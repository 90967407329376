import Vue from 'vue'
import store from './store/index.js'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/iconfont/iconfont.css'
import './assets/global.css' // 引入全局样式
import moment from 'moment'
import VueRouter from 'vue-router'
import xcUtils from './utils/xcUtils'
import curUtils from './utils/curUtils'
import userInfo from './utils/userInfo'
import chnEngPublicRequest from './utils/chnEngPublicRequest'
import upLoadFileRequest from './utils/upLoadFileRequest'
import chnEngStatusCode from './utils/chnEngStatusCode'
import echarts from 'echarts'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import './style/common.less'
import http from './http/request'
import env from './http/env.js'
import downLoadFile from './utils/downLoadFile'
import cebsbLoading from '@/components/cebsbLoading/index.js'
import './utils/directive'
// 按钮组件 全局使用
import qxButton from '@/permission/qxButton.vue'
import gameStageBackview from '@/permission/gameStageBackview.vue'
import gameToolBackView from '@/permission/gameToolBackView.vue'
import gameTransactionBackview from '@/permission/gameTransactionBackview.vue'
import ibcManager from './views/home/raceManagement/ibc/ibc_child_view/ibcManager'
Vue.component('qxButton', qxButton)
Vue.component('gameStageBackview', gameStageBackview)
Vue.component('gameToolBackView', gameToolBackView)
Vue.component('gameTransactionBackview', gameTransactionBackview)
Vue.component('store', store)
Vue.use(cebsbLoading)
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3
})
// 路由监听
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

// 路由跳转结束
router.afterEach(() => {
  NProgress.done()
})
Vue.prototype.$xcUtils = xcUtils
Vue.prototype.$curUtils = curUtils
Vue.prototype.$userInfo = userInfo
Vue.prototype.$chnEngPublicRequest = chnEngPublicRequest
Vue.prototype.$upLoadFileRequest = upLoadFileRequest
Vue.prototype.$chnEngStatusCode = chnEngStatusCode
Vue.prototype.$http = http
Vue.prototype.$env = env
Vue.prototype.$downLoadFile = downLoadFile
Vue.prototype.$ibcManager = ibcManager
Vue.prototype.$echarts = echarts
moment.locale('zh-cn')
Vue.prototype.$moment = moment
Vue.config.productionTip = false
Vue.filter('dateFormat', function (originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, 0)
  const d = (dt.getDate() + '').padStart(2, 0)
  const hh = (dt.getHours() + '').padStart(2, 0)
  const mm = (dt.getMinutes() + '').padStart(2, 0)
  const ss = (dt.getSeconds() + '').padStart(2, 0)
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
