import Vue from 'vue'
import {
  Button, Form, FormItem, Input, Message, Container, Header, Aside, Main, Menu, Submenu, MenuItem, Breadcrumb, BreadcrumbItem,
  Card, Row, Col, Table, TableColumn, Switch, Tooltip, Pagination, Dialog, MessageBox, Tag, Tree, Select, Option, Cascader,
  Alert, Tabs, TabPane, Upload, Steps, Step, CheckboxGroup, Checkbox, Link, RadioGroup, Radio, DatePicker, Timeline, TimelineItem,
  InputNumber, Autocomplete, Dropdown, DropdownMenu, DropdownItem, Loading, Transfer, Image, PageHeader, Popover, Badge, OptionGroup,
  Descriptions, DescriptionsItem, Divider, progress, Statistic, Drawer
} from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Select)
Vue.use(Option)
Vue.use(Cascader)
Vue.use(Alert)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Upload)
Vue.use(Steps)
Vue.use(Step)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Link)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(DatePicker)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(InputNumber)
Vue.use(Autocomplete)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Transfer)
Vue.use(Image)
Vue.use(PageHeader)
Vue.use(Popover)
Vue.use(OptionGroup)
Vue.use(Badge)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Divider)
Vue.use(progress)
Vue.use(Statistic)
Vue.use(Drawer)
const msgbox = MessageBox
Vue.prototype.$message = Message
Vue.prototype.$confirm = msgbox.confirm
Vue.prototype.$msgbox = msgbox
Vue.prototype.$alert = msgbox.alert
Vue.prototype.$loading = Loading.service
