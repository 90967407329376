export default {
  // ==================== 开启加载 ====================
  openLoading (that) {
    that.loading = that.$loading({
      lock: true,
      text: '拼命加载中……',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
  },
  // ==================== 关闭加载 ====================
  closeLoading (that) {
    that.loading && that.loading.close()
  }
}
