export default {
  /**
   * 活动类型
   */
  activityType: 1, // 代表英文活动
  itsoActivityType: 2, // 代表团体赛
  activityCourseType: 4, // 代表汉英课程
  ibcActivityType: 3, // 代表IBC
  /**
   * 登录code码
   */
  LOGINTYPE: '3', // 代表erp
  /**
  * 获取验证码状态
   */
  LOGINSMSTYPE: '7', // 登录
  /**
   * 跳转订单来源
   */
  registrantsOrder: '1', // 个人跳转
  registrantsSchoolOrder: '2', // 学校报名个人信息
  teamSchoolOrder: '3', // 学校报名团体信息
  /**
   * 默认赛季
   */
  defaultSeason: '2024',
  itsoDefaultSeason: '2024',
  /**
   * 判断学校整体参加个人考量是否上报
   */
  ENROLLMENT_NO: 3001, // 未提交
  ENROLLMENT_NO_PAY: 3002, // 已提交未支付
  ENROLLMENT_YES_PAY: 3003, // 已支付
  /**
   * 支付来源
   */
  PAY_ORDERSOURCE: 0, // 网站
  /**
   * 支付类型
   */
  PAY_ORDERTYPE: 0, // 网站
  /**
   * 商品ID
   */
  PRODUCT_SCHOOLPERSON: '1', // 学校报名个人赛
  /**
   * 产品规格类型Code码
   */
  PRODUCT_SPECSTYPE_CODE_SCHOOLPERSON: 1, // 学校报名个人赛
  /**
   * 产品规格类型内容Code码
   */
  PRODUCT_SPECSTYPE_VALUE_CODE_SCHOOLPERSON: 1, // 学校报名个人赛
  /**
   * 商品productFlag
   */
  APPLY_TEST_PAPER: 'APPLY_TEST_PAPER', // 个人赛申报考量
  /**
   * 开通方式
   */
  OPEN_TYPE_DATE: 1, // 开通日期
  OPEN_TYPE_DAYS: 2, // 开通天数
  OPEN_TYPE_DEFAULT: 3, // 产品默认方式
  /**
   * 教师身份
   * 1管理员 2个人赛， 3团体赛 4社团
   */
  ADMINISTRATOR_ROLEID: '1',
  ADMINISTRATOR_INDIVIDUAL: '2',
  ADMINISTRATOR_TEAN: '3',
  ADMINISTRATOR_ASSOCIATION: '4',

  /**
   * 权限flag
   */
  race_management: 'race_management', // 赛务管理
  individual_race_management: 'individual_race_management', // 个人赛赛务管理
  PRIMARY_SELECTION: 'PRIMARY_SELECTION', // 赛务初选
  INDIVIDUAL_FINAL: 'INDIVIDUAL_FINAL', // 赛务复选
  CITY_REGIONAL_COMPETITION: 'CITY_REGIONAL_COMPETITION', // 城市区域选
  CITY_HALF: 'CITY_HALF', // 城市半决选
  CITY_FINALS: 'CITY_FINALS', // 城市总决选
  WEB_FINALS: 'WEB_FINALS', // 网络总决选
  NATIONAL_FINALS_GROUP: 'NATIONAL_FINALS_GROUP', // 全国有组别
  NATIONAL_FINALS: 'NATIONAL_FINALS', // 全国无组别
  // 全国无组别
  tidings_of_glad_tidings: 'tidings_of_glad_tidings', // 喜报制作
  team_race_management: 'team_race_management', // 团体赛赛务管理
  individual_gametime_manage: 'individual_gametime_manage', // 比赛时间管理
  examination_room_list: 'examination_room_list', // 线下考场管理
  examination_onroom_list: 'examination_onroom_list', // 线上考场管理
  network_room_list: 'network_room_list', // 网络赛考场管理
  photo_wall_audit: 'photo_wall_audit', // 国赛照片墙审核
  exam_management: 'exam_management', // 考务管理
  individual_examination_management: 'individual_examination_management', // 个人赛考务管理
  paper_application_management: 'paper_application_management', // 试卷申请管理
  signup_statistics_management: 'signup_statistics_management', // 报名信息统计
  schoolToBeAudited: 'schoolToBeAudited', // 待审核学校
  // individual_school_considerations: 'individual_school_considerations', // 试卷审批
  examination_teamhome_manage: 'examination_teamhome_manage', // 团体赛考务管理
  examination_club_management: 'examination_club_management', // 俱乐部报名信息
  examination_team_management: 'examination_team_management', // 俱乐部管理
  reset_player_score: 'reset_player_score', // 重置选手成绩
  change_player_gametype: 'change_player_gametype', // 修改参赛方式
  exempt_player_game: 'exempt_player_game', // 免初赛管理
  answer_player_form: 'answer_player_form', // 复赛表单答题
  answer_player_code: 'answer_player_code', // 复赛表单解锁码
  ibc_searchscore_info: 'ibc_searchscore_info', // 成绩查询
  ibc_score_info: 'ibc_score_info', // 成绩设置
  ibc_round_info: 'ibc_round_info', // 轮次管理
  ibc_examroom_info: 'ibc_examroom_info', // 考场管理
  ibc_register_info: 'ibc_register_info', // 报名信息

  b_data_management: 'b_data_management', // B端数据管理
  c_data_management: 'c_data_management', // C端数据管理
  product_management_home: 'product_management_home', // 产品管理
  academic_management: 'academic_management', // 学术系统
  system_management: 'system_management', // 系统管理
  academic_font_management_home: 'academic_font_management_home', // 词库题型管理
  academic_course_management: 'academic_course_management', // 课程管理

  GAME_PRIMARY_SELECTION: 'GAME_PRIMARY_SELECTION', // 考务初选
  GAME_NDIVIDUAL_FINAL: 'GAME_INDIVIDUAL_FINAL', // 考务复选
  GAME_CITY_REGIONAL_COMPETITION: 'GAME_CITY_REGIONAL_COMPETITION', // 城市区域选
  GAME_CITY_HALF: 'GAME_CITY_HALF', // 城市半决选
  GAME_CITY_FINALS: 'GAME_CITY_FINALS', // 城市总决选
  GAME_WEB_FINALS: 'GAME_WEB_FINALS', // 网络总决选
  GAME_NATIONAL_FINALS_GROUP: 'GAME_NATIONAL_FINALS_GROUP', // 全国有组别
  GAME_NATIONAL_FINALS: 'GAME_NATIONAL_FINALS', // 全国无组别
  b_division_management: 'b_division_management', // B端赛区管理
  b_school_management: 'b_school_management', // B端学校管理
  b_matchpoint_management: 'b_matchpoint_management', // B端机构管理
  c_player_registInfo: 'c_player_registInfo', // C端选手管理
  c_player_teacherinfo: 'c_player_teacherinfo', // C端指导教师管理
  product_planbee_management: 'product_planbee_management', // 蜂计划管理
  product_management: 'product_management', // 产品订单管理
  product_return_management: 'product_return_management', // 待处理退款订单
  product_management1: 'product_management1', // 缴费订单状态
  product_management2: 'product_management2',
  product_management3: 'product_management3',
  product_management4: 'product_management4',
  product_management11: 'product_management11',
  thesaurus_management: 'thesaurus_management', // 字库订单管理
  launch_product_management: 'launch_product_management', // 开通订单管理
  course_schoolorder_management: 'course_schoolorder_management', // 学校课程管理
  course_teacherorder_management: 'course_teacherorder_management', // 教师课程管理
  productcode_management: 'productcode_management', // 兑换码管理
  productcoupon_management: 'productcoupon_management', // 优惠券管理
  academic_ppt_management: 'academic_ppt_management', // PPT制作
  academic_word_management: 'academic_word_management', // 单词管理
  academic_wordgroup_management: 'academic_wordgroup_management', // 词库管理
  academic_questionType_management: 'academic_questionType_management', // 题型管理
  academic_question_management: 'academic_question_management', // 题库管理
  academic_examination_management: 'academic_examination_management', // 试卷管理
  academic_examination_fengtalk: 'academic_examination_fengtalk', // 蜂Talk
  academic_message_feedback: 'academic_message_feedback', // 消息反馈
  system_management_user: 'system_management_user', // 账号管理
  system_management_section: 'system_management_section', // 部门管理
  system_management_position: 'system_management_position', // 岗位管理
  system_management_authority: 'system_management_authority', // 权限管理
  system_management_dictionary: 'system_management_dictionary', // 字典管理
  academic_spbcn_management: 'academic_spbcn_management', // 个人赛裁判系统
  academic_itso_management: 'academic_itso_management', // 团体赛裁判系统
  academic_ibc_management: 'academic_ibc_management', // IBC裁判系统
  pczx_room_management: 'pczx_room_management', // 拼词之星比赛房间管理
  pczx_signin_management: 'pczx_signin_management', // 拼词之星比赛签到管理
  pczx: 'pczx'
}
