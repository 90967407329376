// directive.js （和main.js同级）
import Vue from 'vue'

// 滚动加载更多
Vue.directive('loadMore', {
  bind (el, binding) {
    // 获取element，定义scroll
    const selectDom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
    selectDom.addEventListener('scroll', function () {
      const height = this.scrollHeight - this.scrollTop <= this.clientHeight
      if (height) {
        binding.value()
      }
    })
  }
})
