import axios from 'axios'
import qs from 'qs'
import Vue from 'vue'
export default {
  downLoadFileWithUrl: async function (that, requestUrl, formData) {
    Vue.$showLoading.show()
    axios({
      headers: {
        'Content-Type': 'application/json;charset=UTF-8;',
        Authorization: localStorage.getItem('Authorization')
      },
      method: 'GET',
      url: requestUrl,
      params: formData,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    }).then((res) => {
      Vue.$showLoading.hide()
      console.log('res.data', res.data)
      if (res.data.code === 200) {
        if (res.data.data.noPromotedUrl) {
          var downloadUrl = [res.data.data.noPromotedUrl, res.data.data.promotedUrl]
          for (let index = 0; index < downloadUrl.length; index++) {
            const iframe = document.createElement('iframe')
            iframe.style.display = 'none' // 防止影响页面
            iframe.style.height = 0 // 防止影响页面
            iframe.src = downloadUrl[index]
            document.body.appendChild(iframe) // 这一行必须，iframe挂在到dom树上才会发请求
            // 之后删除
            setTimeout(() => {
              iframe.remove()
            }, 1000)
          }
        } else {
          window.location.href = res.data.data
        }
      } else {
        Vue.prototype.$message.error(res.data.msg)
      }
    }).catch((err) => {
      Vue.$showLoading.hide()
      console.log(err)
    })
  },
  downLoadFileWithFile: async function (that, requestUrl, fileName, formData) {
    Vue.$showLoading.show()
    console.log('formData', formData, requestUrl, localStorage.getItem('Authorization'))
    axios({
      headers: {
        'Content-Type': 'application/json;charset=UTF-8;',
        Authorization: localStorage.getItem('Authorization')
      },
      responseType: 'arraybuffer',
      method: 'GET',
      url: requestUrl,
      params: formData
    }).then((res) => {
      Vue.$showLoading.hide()
      const data = res.data
      try {
        const enc = new TextDecoder('utf-8')
        const resJson = JSON.parse(enc.decode(new Uint8Array(data)))
        that.$message.error(resJson.msg)
      } catch (error) {
        Vue.$showLoading.hide()
        // console.log(error)
        const url = window.URL.createObjectURL(new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
        // console.log('res.headers', res.headers)
        // const fileNames = res.headers['content-disposition'] // 获取到Content-Disposition;filename
        // const regFileNames = fileNames.match(/=(.*)$/)[1] // 文件名称
        // const regFileNames = '模板文件.xlsx' // 文件名称
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.setAttribute('download', decodeURI(fileName))
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        this.dialogInfo.dialogVisible = false
      }
    }).catch((err) => {
      Vue.$showLoading.hide()
      console.log(err)
    })
  }
}
