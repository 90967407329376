import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// 使用vuex
Vue.use(Vuex)

// 创建vue实例
const store = new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['permission', 'menuList', 'userJobInfo', 'indexForm']
  })],
  state: {
    permission: [],
    menuList: [],
    userJobInfo: {},
    indexForm: {}
  },
  mutations: {
    SET_PERMISSION (state, permission) {
      state.permission = permission
    },
    SET_MENULIST (state, menuList) {
      state.menuList = menuList
    },
    SET_JOBINFO (state, userJobInfo) {
      state.userJobInfo = userJobInfo
    },
    SET_INDEXFORM (state, indexForm) {
      state.indexForm = indexForm
    }
  },
  actions: {
    SET_PERMISSION (context, permission) {
      context.commit('SET_PERMISSION', permission)
    },
    SET_MENULIST (context, menuList) {
      context.commit('SET_MENULIST', menuList)
    },
    SET_JOBINFO (context, userJobInfo) {
      context.commit('SET_JOBINFO', userJobInfo)
    },
    SET_INDEXFORM (context, indexForm) {
      context.commit('SET_INDEXFORM', indexForm)
    }
  }
})
// 导出store
export default store
