<template>
    <div>
        <div class="processed-list-con" @click="racingToolClicked(itemData)">
            <div class="processed-image"
            :class="this.hasPerms(itemData.flag) === true ? ('coloricon' + itemData.menuTag) : 'card-backgroundgray'"
            >
                <i class="icon iconfont" :class="itemData.icon"></i>
            </div>
            <div class="processed-count">
                <div class="processed-desc">{{ itemData.number }}</div>
                <div class="processed-title">{{ itemData.title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { hasPermission } from '@/permission/index.js'
export default {
  props: {
    itemData: {
      type: Object,
      default: function () { return {} }
    }
  },
  methods: {
    racingToolClicked (itemData) {
      if (this.hasPerms(itemData.flag)) {
        this.$emit('itemClicked', itemData)
      } else {
        this.$message.warning('暂无操作权限')
      }
    },
    hasPerms: function (perms) {
      // 根据权限标识和外部指示状态进行权限判断
      return hasPermission(perms) // & !this.disabled
    }
  }
}
</script>

<style>
</style>
