<template>
    <div>
        <div
            shadow="hover"
            style="border: none"
            class="system-btn"
            @click="scheduleCardTap(itemData)"
            :class="
                this.hasPerms(
                    itemData.authorityFlag
                        ? itemData.authorityFlag
                        : itemData.flag
                ) === true
                    ? 'card-background' + (itemIndex + 1)
                    : 'card-backgroundgray'
            "
        >
            <i v-if="itemData.icon" class="icon iconfont" :class="itemData.icon"></i>
            <div>
                <div class="schedule_gameName">
                    {{ itemData.scheduleName }}
                </div>
                <!-- <div class="schedule_gameNumber" v-if="itemData.promotionNum">
                参赛 {{ itemData.signUpNum }} <br />
                晋级 {{ itemData.promotionNum }}
            </div> -->
                <div class="schedule_gameNumber">
                    {{ itemData.descStr }}
                </div>
            </div>
            <!-- <el-progress
                v-if="itemData.promotionNum"
                class="schedule-progress"
                type="circle"
                :percentage="itemData.percent"
                color="#ffffff"
                define-back-color="rgba(255,255,255,0.3)"
                :show-text="false"
                :width="42"
                stroke-linecap="square"
                :stroke-width="8"
            ></el-progress> -->
        </div>
    </div>
</template>

<script>
import { hasPermission } from '@/permission/index.js'
export default {
  props: {
    itemData: {
      type: Object,
      default: function () { return {} }
    },
    itemIndex: {
      type: Number
    }
  },
  methods: {
    scheduleCardTap (itemData) {
      if (this.hasPerms(itemData.authorityFlag ? itemData.authorityFlag : itemData.flag)) {
        this.$emit('itemClicked', itemData)
      } else {
        this.$message.warning('暂无操作权限')
      }
    },
    hasPerms: function (perms) {
      // 根据权限标识和外部指示状态进行权限判断
      return hasPermission(perms) // & !this.disabled
    }
  }
}
</script>

<style lang="less" scoped>
.system-btn {
    border-radius: 8px;
    padding: 20px 0;
    margin-right: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    width: 260px;
    .icon {
        padding: 8px 10px;
        margin-left: 20px;
       font-size: 30px;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 100%;
    }
    .schedule_gameName {
        text-align: left;
        color: white;
        font-size: 20px;
        margin-bottom: 6px;
        margin-left: 20px;
    }

    .schedule_gameNumber {
        text-align: left;
        color: white;
        font-size: 12px;
        margin-left: 20px;
    }
}

</style>
