import axios from 'axios'// 引入axios
import env from '../http/env'
import router from '../router'
import Vue from 'vue'
import { Promise } from 'core-js'
import Nprogress from 'nprogress'
import qs from 'qs'
// application/json;charset=UTF-8;
// 创建axios实例
const service = axios.create({
  // 这里拿线上接口测试
  baseURL: env.baseIP,
  headers: { // 请求头
    'Content-Type': 'application/json;charset=UTF-8;'
  },
  timeout: 180000 // 超时时间
})
// 请求拦截器
service.interceptors.request.use(
  config => {
    if ((config.url !== '/cityMatch/final/setPromotion' && config.url !== '/net/saveScores' && config.url !== '/common/screen' && config.url !== '/nationalMatch/setPromotion')) {
      Vue.$showLoading.show()
      Nprogress.start()
    }
    if (localStorage.getItem('Authorization')) {
      config.headers.Authorization = localStorage.getItem('Authorization')
    }
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return config // 记得一定要 返回config
  },
  error => {
    Vue.$showLoading.hide()
    Nprogress.done()
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use((res) => {
  Nprogress.done()
  Vue.$showLoading.hide()
  if (res.status && res.status === 200) {
    if (res.headers && res.headers.authorization) {
      const authorization = res.headers.authorization
      localStorage.setItem('Authorization', authorization)
    }
    if (res.data.code === 200 || res.data.code === 4008) {
      return res.data
    } else if (res.data.code === 2014) { // 有未完善的资料
      if ((res.config.url === '/cityMatch/final/setPromotion' || res.config.url === '/net/saveScores' || res.config.url === '/common/screen')) {
        return
      }
      Vue.prototype.$confirm('您有未完善的信息，是否现在去完善?', '提示', {
        confirmButtonText: '去完善',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        router.push({
          path: '/cebsbUserInfo?pushUrl=/speRegisterFirCompetition'
        })
      }).catch(() => {
      })
    } else if (res.data.code === 401) {
      if ((res.config.url === '/cityMatch/final/setPromotion' || res.config.url === '/net/saveScores' || res.config.url === '/common/screen')) {
        return
      }
      Vue.prototype.$message.error('登录已过期，请重新登录！')
      localStorage.removeItem('Authorization') // 移除token，跳转至登录
      router.push({
        path: '/login',
        params: {
          pushUrl: ''
        }
      })
    } else if (res.data.code === 3094) {
      return res.data
    } else {
      if ((res.config.url === '/cityMatch/final/setPromotion' || res.config.url === '/net/saveScores' || res.config.url === '/common/screen')) {
        return
      }
      Vue.prototype.$message.error(res.data.msg)
    }
    return Promise.reject(res)
  }
}, err => {
  Vue.$showLoading.hide()
  Nprogress.done()
  console.log('err', err)
  // if (err.code === 'ERR_BAD_RESPONSE') {
  //   if (err.response.data.code === 401) {
  //     Vue.prototype.$message.error('登录已过期，请重新登录！')
  //     localStorage.removeItem('Authorization') // 移除token，跳转至登录
  //     router.push({
  //       path: '/login',
  //       params: {
  //         pushUrl: ''
  //       }
  //     })
  //   } else {
  //     Vue.prototype.$message.error('无服务，请稍后重试！')
  //   }
  // } else {
  //   if (err.status === 401) {
  //     Vue.prototype.$message.error('登录已过期，请重新登录！')
  //     localStorage.removeItem('Authorization') // 移除token，跳转至登录
  //     router.push({
  //       path: '/login',
  //       params: {
  //         pushUrl: ''
  //       }
  //     })
  //   } else {
  //     Vue.prototype.$message.error('无服务，请稍后重试！')
  //   }
  // }
  return Promise.reject(err)
})
export default service
