export default {
  /**
   * 方法导航
   *
   * 校验手机号 checkPhone
   * 校验身份证 checkIdCard
   * 时间戳转时期 timestamp2Date
   */

  /**
   * 校验手机号
   * @param phone 手机号
   * @returns true-成功，false-失败
   */
  checkPhone: function (phone) {
    const reg = /^[1][3-9][0-9]{9}$/
    return reg.test(phone)
  },
  /**
   * 校验邮箱
   * @param email 邮箱
   * @returns true-成功，false-失败
   */
  checkEmail: function (email) {
    if (email.length === 0) {
      return false
    }
    const str = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
    if (str.test(email)) {
      return true
    } else {
      return false
    }
  },
  /**
   * 校验身份证
   * @param {Number} timeStamp 传入的时间戳(毫秒级)
   * @param {Number} type 返回的时间类型(默认0)
   *                      0: ‘YYYY-MM-DD hh:mm:ss’
   *                      1: ‘YYYY-MM-DD’
   */
  checkIdCard: function (card) {
    var aIdentityCodeCity = { // 城市代码列表
      11: '北京',
      12: '天津',
      13: '河北',
      14: '山西',
      15: '内蒙古',
      21: '辽宁',
      22: '吉林',
      23: '黑龙江 ',
      31: '上海',
      32: '江苏',
      33: '浙江',
      34: '安徽',
      35: '福建',
      36: '江西',
      37: '山东',
      41: '河南',
      42: '湖北 ',
      43: '湖南',
      44: '广东',
      45: '广西',
      46: '海南',
      50: '重庆',
      51: '四川',
      52: '贵州',
      53: '云南',
      54: '西藏 ',
      61: '陕西',
      62: '甘肃',
      63: '青海',
      64: '宁夏',
      65: '新疆',
      71: '台湾',
      81: '香港',
      82: '澳门',
      91: '国外 '
    }
    let pass = true
    // 是否为空
    if (pass && card === '') pass = false
    // 校验长度，类型
    var reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/ // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
    if (reg.test(card) === false) {
      pass = false
    }
    // 检查省份,取身份证前两位，校验省份
    var province = card.substr(0, 2)
    if (aIdentityCodeCity[province] === undefined) {
      pass = false
    }
    // 校验生日,检查生日是否正确，15位以'19'年份来进行补齐。
    var len = card.length
    var arrData, year, month, day, birthday, now, nowYear, time
    // 身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
    if (len === '15') {
      var reFifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/
      arrData = card.match(reFifteen) // 正则取号码内所含出年月日数据
      year = arrData[2]
      month = arrData[3]
      day = arrData[4]
      birthday = new Date('19' + year + '/' + month + '/' + day)
      now = new Date()
      nowYear = now.getFullYear()
      // 年月日是否合理
      if (birthday.getFullYear() === '19' + year && (birthday.getMonth() + 1) === month && birthday.getDate() === day) {
        // 判断年份的范围（3岁到150岁之间)
        time = nowYear - '19' + year
        if (time >= 3 && time <= 150) {
          pass = true
        }
        pass = false
      }
      pass = false
    }
    // 身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
    if (len === '18') {
      var reEighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/
      arrData = card.match(reEighteen) // 正则取号码内所含出年月日数据
      year = arrData[2]
      month = arrData[3]
      day = arrData[4]
      birthday = new Date(year + '/' + month + '/' + day)
      now = new Date()
      nowYear = now.getFullYear()
      // 年月日是否合理
      if (birthday.getFullYear() === year && (birthday.getMonth() + 1) === month && birthday.getDate() === day) {
        // 判断年份的范围（3岁到150岁之间)
        time = nowYear - year
        if (time >= 3 && time <= 150) {
          pass = true
        }
        pass = false
      }
      pass = false
    }
    // 检验位的检测
    var arrInt, arrCh, cardTemp, i
    if (card.length === '15') { // 15位转18位
      arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
      cardTemp = 0
      card = card.substr(0, 6) + '19' + card.substr(6, card.length - 6)
      for (i = 0; i < 17; i++) {
        cardTemp += card.substr(i, 1) * arrInt[i]
      }
      card += arrCh[cardTemp % 11]
    }
    len = card.length
    if (len === '18') {
      arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
      cardTemp = 0
      var valnum
      for (i = 0; i < 17; i++) {
        cardTemp += card.substr(i, 1) * arrInt[i]
      }
      valnum = arrCh[cardTemp % 11]
      if (valnum === card.substr(17, 1)) {
        pass = true
      }
      pass = false
    }
    if (pass) {
      return true
    } else {
      return false
    }
  },
  /**
   * 时间戳转日期
   * @param {Number} timeStamp 传入的时间戳(毫秒级)
   * @param {Number} type 返回的时间类型(默认0)
   *                      0: ‘YYYY-MM-DD hh:mm:ss’
   *                      1: ‘YYYY-MM-DD’
   */
  timestamp2Date: function (timeStamp, type) {
    if (timeStamp === undefined || timeStamp === '') {
      return ''
    }
    const date = new Date(parseInt(timeStamp))
    var YY = date.getFullYear()
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours())
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
    if (type === 1) {
      return YY + '-' + MM + '-' + DD
    }
    return YY + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss
  },

  /**
   * 时间戳转日期
   * @param {Number} timeStamp 传入的时间戳(毫秒级)
   * @param {Number} type 返回的时间类型(默认0)
   *                      0: ‘YYYY-MM-DD hh:mm:ss’
   *                      1: ‘YYYY-MM-DD’
   */
  timestamp3Date: function (timeStamp) {
    if (timeStamp === undefined || timeStamp === '') {
      return ''
    }
    const date = new Date(parseInt(timeStamp))
    var YY = date.getFullYear()
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours())
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
    return YY + '/' + MM + '/' + DD + ' ' + hh + ':' + mm + ':' + ss
  },
  /**
   * 金额保留两位小数并补足0
   */
  toFixedPrice (num) {
    return (Math.round(num * 100) / 100).toFixed(2)
  },
  /**
   * json对象序列化 {a:1, b:2} -> "a=1&b=2"
   */
  serialize (obj) {
    return Object.keys(obj).map(key => {
      return `${key}=${obj[key]}`
    }).join('&')
  },
  addZero (num) {
    return String(num).length === 1 ? `00${num}` : String(num).length === 2 ? `0${num}` : num
  }
}
