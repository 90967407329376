<template>
    <div id="app">
        <!-- 引入阿里字体样式 -->
        <link
            rel="stylesheet"
            href="//at.alicdn.com/t/font_2292361_sbn60avye6c.css"
        />
        <transition name="slide-fade">
            <router-view v-if="isRouterAlive"></router-view>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'app',
  mounted () {
    window.addEventListener('beforeunload', this.onRefresh)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.onRefresh)
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    onRefresh () {
      console.log('刷新了。。。。。。。。。。。')
    },
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },

  components: {}
}
</script>

<style>
@import "assets/font/font.css";
.slide-fade {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
}
.slide-fade-enter,
.slide-fade-leave-to {
    left: 0;
    top: 0;
    right: 0;
    position: absolute;
    transform: translateY(100%);
}
.slide-fade-enter-active {
    transition: all 0.4s ease;
}
.slide-fade-leave-active {
    transition: all 0.4s ease;
    transform: translateY(-100%);
    z-index: 100;
}
.special_search .el-input__inner:focus {
    border-width: 3px;
}
</style>
